import React from 'react'
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import { Divider } from '../libs/libSupport';
import IconButton, { ButtonsRow } from '../libs/IconButtonV2';
import SamModal from '../libs/SamModalV2';
import { formatImageUrl } from '../libs/ImageFormatter';
import { useNavigate } from 'react-router-dom';
import { DateSupport } from '../libs/api-shared/date-support';
import Comments from './Comments';
import Pager from '../libs/Pager';
import useData from './useBlogData';
import Subscribe from './SubscribeForm';
import { useGlobalContext } from '../libs/SamState';

import { ImageSizeEnum } from '../interfaces/lib-api-interfaces';
import { BlogEntryList, BlogEntryRecord, BlogListTypeEnum } from '../interfaces/lib-websites-interfaces';
import { IconButtonAlignEnum } from '../interfaces/lib-react-interfaces';

import app from '../appData';
import { cleanAndSplitParagraphs } from '../libs/RenderCleanHtml';

const maxSummaryHeight = 144;

const chunkSize = 10;

const ContentContainer = styled.div<{ backColor: string; paddingTop: number; borderTop?: string }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.backColor};
    padding-top: ${props => props.paddingTop}px;
    border-top: ${props => props.borderTop};
    h2 {
        width: 100%;
        font-size: 24px;
        font-weight: bold;
        line-height: 44px;
        margin-bottom: 8px;
        text-align: center;
    }
`
// if there is only one entry it will be shown in full; else the first is shown with enlarged photo and rest of list is shown with thumbs
// assumes there is at least one entry
interface BlogContentProps {
    list: BlogEntryList;
}
const BlogContent: React.FC<BlogContentProps> = (props) => {
    const [doScroll, setDoScroll] = React.useState(false);
    const { setListIndex, getServerEntryCount } = useData();

    React.useEffect(() => {
        if (doScroll) {
            setDoScroll(false);
            window.scrollTo(0, 0);
        }
    }, [doScroll]);

    const loadPage = (index: number) => {
        setListIndex(index);
    }

    const recordCount = getServerEntryCount();

    return (
        <ContentContainer backColor={app.riverThemes.contentBackColor} paddingTop={props.list.entries.length ? 16 : 0}>
            {props.list.entries.length === 1 ? (
                <RenderFullEntry entry={props.list.entries[0]} />
            ) : (
                <>
                    {props.list.entries.map((entry, index) => {
                        if (index === 0 && props.list.criteria.list_type === BlogListTypeEnum.recent) {
                            return <RenderFirstEntry key={index} entry={entry} />
                        } else {
                            return <RenderEntryInList key={index} entry={entry} backColor={app.riverThemes.contentBackColor} />
                        }
                    })}
                    {recordCount > app.pageSize &&
                        <Pager chunkSize={chunkSize} recordCount={getServerEntryCount()} selectablePageCount={20} loadChunk={loadPage} currColor={app.riverThemes.lightGold} />
                    }
                </>
            )}
        </ContentContainer>
    )
}

const FullEntryText = styled.div`
    text-align: left;
    margin: 16px 32px 0 32px;
`
const RelatedText = styled.p`
    font-weight: bold;
    margin: 4px 8px 0 24px;
`
const ButtonsSubcontent = styled.div`
    display: flex;
    justify-content: flex-start;
`
const TopicsAndShareRow = styled.div<{ backColor: string }>`
    background-color: ${props => props.backColor};
    height: 70px;
    margin-top: 48px;
    margin-bottom: 56px;
    padding: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`
// #region RENDER FULL ENTRY WITH COMMENTS
interface RenderFullEntryProps {
    entry: BlogEntryRecord;
    suppressDate?: boolean;
}
export const RenderFullEntry: React.FC<RenderFullEntryProps> = (props) => {
    const [modalImage, setModalImage] = React.useState<string>();
    const [showSubscribe, setShowSubscribe] = React.useState(false);

    const [useDividers, setUseDividers] = React.useState(false);

    const { getContext, setContext } = useGlobalContext();

    const textDivRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    const navigate = useNavigate();

    const imageClicked = (filename: string) => {
        setModalImage(filename);
    }

    React.useEffect(() => {
        // make sure image src's are valid
        preprocessImages(textDivRef.current, imageClicked);
        setContext(app.positions.scrollToCommentsKey, false);
    }, []);

    const topicClicked = (topic: string) => {
        navigate("/topic/" + topic);
    }
    return (
        <>
            <EntryContainer>
                <Helmet>
                    <meta property='og:url' content={window.location.href} />
                    <meta property='og:title' content='Karinaland River' />
                    <meta property='og:description' content={(props.entry.title ? (props.entry.title + " -- ") : '')
                        + props.entry.content.replace(/<[^>]*>/g, '').replaceAll("&nbsp;", " ").substring(0, 200) + "..."} />
                    <meta property='og:image' content={getFirstImage(props.entry.content)} />
                </Helmet>
                <DateAndShareRow align={props.suppressDate ? IconButtonAlignEnum.right : IconButtonAlignEnum.spaceBetween}>
                    {!props.suppressDate &&
                        <DateBox date={DateSupport.fromString(props.entry.pub_date)} />
                    }
                    <ShareEntry />
                </DateAndShareRow>
                <h2>{props.entry.title}</h2>
                <FullEntryText ref={textDivRef} dangerouslySetInnerHTML={{ __html: props.entry.content }} />
                {useDividers ? (
                    <>
                        <Divider color="black" width="50%" height={1} margin={24} />
                        <ButtonsRow align={IconButtonAlignEnum.spaceBetween} marginLeft={64} marginRight={64}>
                            <ButtonsSubcontent>
                                {props.entry.topics.length &&
                                    <>
                                        <RelatedText>Topics:</RelatedText>
                                        {props.entry.topics.map(topic => {
                                            return <IconButton style={{ marginRight: "12px" }} caption={topic} onClick={() => topicClicked(topic)} />
                                        })}
                                    </>}
                            </ButtonsSubcontent>
                            <ButtonsSubcontent>
                                <IconButton caption="Subscribe to the River" onClick={() => setShowSubscribe(true)} />
                                <ShareEntry marginLeft={16} />
                            </ButtonsSubcontent>
                        </ButtonsRow>
                        <Divider color="black" width="50%" height={1} margin={24} />
                    </>
                ) : (
                    <TopicsAndShareRow backColor={app.riverThemes.sidebarBackColor}>
                        <ButtonsSubcontent>
                            {props.entry.topics.length &&
                                <>
                                    <RelatedText>Topics:</RelatedText>
                                    {props.entry.topics.map(topic => {
                                        return <IconButton style={{ marginRight: "12px" }} caption={topic} onClick={() => topicClicked(topic)} />
                                    })}
                                </>}
                        </ButtonsSubcontent>
                        <ButtonsSubcontent>
                            <IconButton caption="Subscribe to the River" onClick={() => setShowSubscribe(true)} />
                            <ShareEntry marginLeft={16} />
                        </ButtonsSubcontent>
                    </TopicsAndShareRow>
                )}
                <Comments circleClicked={() => setUseDividers(state => !state)} scrollIntoView={getContext(app.positions.scrollToCommentsKey)} entry={props.entry} />
                {modalImage &&
                    <SamModal>
                        <ShowModalImage filename={modalImage} onClose={() => setModalImage(undefined)} />
                    </SamModal>
                }
                {showSubscribe &&
                    <SamModal>
                        <Subscribe onSubmit={() => setShowSubscribe(false)} />
                    </SamModal>
                }
            </EntryContainer>
            <ContentContainer backColor={app.riverThemes.sidebarBackColor} paddingTop={10} borderTop={"48px solid " + app.riverThemes.bannerBackColor}>
                <h2>Other River Posts</h2>
                {props.entry.popular!.map((entry, index) => {
                    return <RenderEntryInList key={index} entry={entry} backColor={app.riverThemes.sidebarBackColor} />
                })}
            </ContentContainer>
        </>
    )
}
// #endregion RENDER FULL ENTRY WITH COMMENTS

const getFirstImage = (html: string): string => {
    const posn = html.indexOf('<img');
    if (posn === -1) {
        return '';
    }
    if (html.toLowerCase().includes("loving to death")) {
        console.log(html.substring(posn));
    }
    let srcPosn = html.indexOf('data-file=', posn);
    if (srcPosn === -1) {
        return '';
    }
    srcPosn += 11;
    const quote = html[srcPosn - 1];
    const endPosn = html.indexOf(quote, srcPosn);
    return (html.substring(srcPosn, endPosn));
}
// #region RENDER FIRST ENTRY IN LIST WITH ENLARGED PHOTO, DATE AND BUTTONS
const EntryContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
`
const DateRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 16px;
`
const DateAndShareRow = styled.div<{ align: IconButtonAlignEnum }>`
    display: flex;
    align-items: center;
    justify-content: ${props => props.align === IconButtonAlignEnum.spaceBetween ? "space-between" : "flex-end"};
    width: 100%;
`
const FirstEntryImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    img {
        max-width: 100%;
        height: auto;
        width: auto;
    }
`
interface RenderFirstEntryProps {
    entry: BlogEntryRecord;
}
const RenderFirstEntry: React.FC<RenderFirstEntryProps> = (props) => {
    const [headerImageUrl, setHeaderImageUrl] = React.useState<string>();
    const [entryId, setEntryId] = React.useState<number>();
    const [scrollUp, setScrollUp] = React.useState(false);

    const navigate = useNavigate();
    const { setContext } = useGlobalContext();

    if (!entryId || entryId !== props.entry.entry_id) {
        setEntryId(props.entry.entry_id);
    }

    React.useEffect(() => {
        if (scrollUp) {
            setScrollUp(false);
            window.scrollTo(0, 0);
        }
    }, [scrollUp]);

    React.useEffect(() => {
        if (entryId) {
            // identify thumb (if showing) and remove images from content
            const filename = getFirstImage(props.entry.content)
            if (filename) {
                setHeaderImageUrl(formatImageUrl(filename, { isCdn: true, sizeDesignator: ImageSizeEnum.magnified }));
            }
        }
    }, [entryId]);

    const moreBtnClicked = () => {
        navigate("/id/" + entryId);
    }
    const commentBtnClicked = () => {
        setContext(app.positions.scrollToCommentsKey, true);
        navigate("/id/" + props.entry.entry_id);
    }

    return (
        <EntryContainer>
            <DateRow>
                <DateBox date={DateSupport.fromString(props.entry.pub_date)} onClick={moreBtnClicked} />
            </DateRow>
            <h2>{props.entry.title}</h2>
            {headerImageUrl &&
                <FirstEntryImageContainer>
                    <img src={headerImageUrl} />
                </FirstEntryImageContainer>
            }
            <RenderSnippetText entry={props.entry} backColor={app.riverThemes.contentBackColor} />
            <EntryListButtonsRow commentCount={props.entry.comment_count} moreBtnClicked={moreBtnClicked} commentBtnClicked={commentBtnClicked} />
        </EntryContainer>
    )
}
// #endregion RENDER FIRST ENTRY IN LIST WITH ENLARGED PHOTO, DATE AND BUTTONS
//--------------------------------------------------------

// #region RENDER NEXT ENTRY IN LIST WITH THUMB, DATE AND BUTTONS
//--------------------------------------------------------
const ListEntryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
    padding: 16px;
    padding-right: 32px;
`
const ImageAndTextWrapper = styled.div`
    display: flex;
`
const ImageContainer = styled.div`
    max-width: 25%;
    min-width: 25%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-left: 0;
    margin-right: 16px;
    margin-top: 8px;
    cursor: pointer;
    img {
        max-width: 100%;
        height: auto;
        width: auto;
    }
`
const TextAndButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
`
interface RenderEntryProps {
    entry: BlogEntryRecord;
    backColor: string;
}
const RenderEntryInList: React.FC<RenderEntryProps> = (props) => {
    const [thumbUrl, setThumbUrl] = React.useState<string>();
    const [scrollUp, setScrollUp] = React.useState(false);

    const navigate = useNavigate();
    const { setContext } = useGlobalContext();

    React.useEffect(() => {
        // identify thumb (if showing) and remove images from content
        const filename = getFirstImage(props.entry.content);
        if (filename) {
            setThumbUrl(formatImageUrl(filename, { isCdn: true }));
        } else {
            setThumbUrl(undefined);
        }
    }, [props.entry.entry_id]);

    React.useEffect(() => {
        if (scrollUp) {
            setScrollUp(false);
            window.scrollTo(0, 0);
        }
    }, [scrollUp]);

    const moreBtnClicked = () => {
        navigate("/id/" + props.entry.entry_id);
    }
    const commentBtnClicked = () => {
        setContext(app.positions.scrollToCommentsKey, true);
        navigate("/id/" + props.entry.entry_id);
    }

    return (
        <ListEntryContainer id={props.entry.entry_id + ''}>
            <DateBox date={DateSupport.fromString(props.entry.pub_date)} onClick={moreBtnClicked} />
            <ImageAndTextWrapper>
                {thumbUrl &&
                    <ImageContainer>
                        <img src={thumbUrl} onClick={moreBtnClicked} />
                    </ImageContainer>
                }
                <TextAndButtonsWrapper>
                        <RenderSnippetText entry={props.entry} backColor={props.backColor} />
                        <EntryListButtonsRow commentCount={props.entry.comment_count} moreBtnClicked={moreBtnClicked} commentBtnClicked={commentBtnClicked} />
                </TextAndButtonsWrapper>
            </ImageAndTextWrapper>
        </ListEntryContainer>
    )
}
//--------------------------------------------------------
const SnippetContainer = styled.div`
    position: relative;
`
const SnippetText = styled.div`
    max-height: ${maxSummaryHeight}px;
    overflow-y: hidden;
    word-break: break-word;
`
const FadeoutOverlay = styled.div<{ backColor: string }>`
    position: absolute;
    top: 80%;
    height: 20%; 
    right: 0;
    width: 30%; 
    background: linear-gradient(to left,${props => props.backColor} 0,${props => props.backColor} 5%,rgba(255, 255, 255, 0) 100%);
    cursor: pointer;
`
const Ellipse = styled.div`
    font-size: 24px;
    position: absolute;
    top: 0;
    right: 0;
`

interface RenderSnippetTextProps {
    entry: BlogEntryRecord;
    backColor: string;
}
const RenderSnippetText: React.FC<RenderSnippetTextProps> = (props) => {
    const [plainText, setPlainText] = React.useState<string>();

    const navigate = useNavigate();

    React.useEffect(() => {
        const text = cleanAndSplitParagraphs(props.entry.content.replace(/<[^>]*>/g, ''));
        setPlainText((props.entry.title ? (props.entry.title + " -- ") : '') + text);
    }, []);

    return (
        <SnippetContainer>
            {plainText &&
                <SnippetText dangerouslySetInnerHTML={{ __html: plainText }} />
            }
            <FadeoutOverlay backColor={props.backColor} onClick={() => navigate("/id/" + props.entry.entry_id)}>
                <Ellipse>&hellip;</Ellipse>
            </FadeoutOverlay>
        </SnippetContainer>
    )
}
//--------------------------------------------------------
interface EntryListButtonsRowProps {
    commentCount?: number;
    moreBtnClicked: () => void;
    commentBtnClicked: () => void;
}
const EntryListButtonsRow: React.FC<EntryListButtonsRowProps> = (props) => {
    let caption: string;
    if ((props.commentCount ?? 0) === 0) {
        caption = "Post a comment";
    } else {
        caption = props.commentCount + " comment" + (props.commentCount! > 1 ? 's' : '');
    }
    return (
        <ButtonsRow align={IconButtonAlignEnum.spaceBetween}>
            <IconButton caption={caption} onClick={props.commentBtnClicked} />
            <IconButton caption="Read more" onClick={props.moreBtnClicked} />
        </ButtonsRow>
    )
}
//--------------------------------------------------------
const ShareEntryContainer = styled.div<{ marginLeft?: number }>`
    position: relative;
    margin-left: ${props => props.marginLeft}px;
`
const ShareButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${app.themes.buttonStyles.backgroundColor};
    color: ${app.themes.buttonStyles.color};
    padding: 8px;
    i {
        display: inline;
        margin-right: 8px;
    }
`
const PopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1000;
    font-size: 18px;
    color: black;
    top: 0;
    right: 0;
    background-color: white;
    div:hover {
        background-color: ${app.riverThemes.lightGold};
    }
`
const PopupTitle = styled.p`
    line-height: 38px;
    margin: 0;
    border: 2px solid black;
    text-align: center;
    font-weight: bold;
`
const ListItem = styled.div`
    display: flex;
    align-items: center;
    height: 38px;
    background-color: white;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
    i {
        margin-right: 16px;
    }
`
enum ShareTypeEnum { link, facebook, twitter, pinterest, email }
interface ShareListRecord {
    caption: string;
    action: ShareTypeEnum;
    icon: string;
}
interface ShareEntryContainerProps {
    marginLeft?: number;
}
export const ShareEntry: React.FC<ShareEntryContainerProps> = (props) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const mouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsOpen(true);
    }

    const handleClick = (shareType: ShareTypeEnum) => {
        if (shareType === ShareTypeEnum.facebook) {
            const FB = (window as any).FB;
            FB.ui({
                method: 'share',
                href: "https://" + app.targetDomain + window.location.pathname
            }, function (response: any) {
                if (response && !response.error_message) {
                    alert('Posting completed.');
                } else {
                    alert('Error while posting.');
                }
            });
        }
    }

    const shareList: ShareListRecord[] = [
        { caption: "Get link", action: ShareTypeEnum.link, icon: "fa fa-link" },
        { caption: "Facebook", action: ShareTypeEnum.facebook, icon: "fab fa-facebook" },
        { caption: "Twitter", action: ShareTypeEnum.twitter, icon: "fab fa-twitter" },
        { caption: "Email", action: ShareTypeEnum.email, icon: "fa fa-envelope" },
    ];

    return (
        <ShareEntryContainer marginLeft={props.marginLeft ?? 0} onMouseEnter={mouseEnter} onMouseLeave={() => setIsOpen(false)}>
            <ShareButton>
                <i className="fas fa-share-alt" />
                <span>Share</span>
            </ShareButton>
            {isOpen &&
                <PopupContainer>
                    <PopupTitle>
                        Share
                    </PopupTitle>
                    {shareList.map(record => {
                        return (
                            <ListItem onClick={() => handleClick(record.action)}>
                                <i className={record.icon} />
                                {record.caption}
                            </ListItem>
                        )
                    })}
                </PopupContainer>
            }
        </ShareEntryContainer>
    )
}
//--------------------------------------------------------
// set all images except first to display or non-display; return filename of first image for use as thumb or header
// displayAll will be true if this is the only entry being shown
export const preprocessImages = (entryDiv: HTMLDivElement, imageClicked?: (filename: string) => void): string | null => {
    const images = entryDiv.getElementsByTagName("img");
    let filename = null;
    if (images.length) {
        filename = images[0].getAttribute("data-file");
        for (let i = 0; i < images.length; i++) {
            const filename = images[i].getAttribute("data-file")!;
            images[i].setAttribute("src", formatImageUrl(filename, { isCdn: true }));
            if (imageClicked) {
                images[i].onclick = () => imageClicked(filename);
                images[i].style.setProperty("cursor", "pointer");
            }
            images[i].style.removeProperty("display");
        }
    }
    const figures = entryDiv.getElementsByTagName("figure");
    for (let i = 0; i < figures.length; i++) {
        figures[i].style.removeProperty("display");
    }
    return filename;
}

//--------------------------------------------------------
const ModalImageContainer = styled.img`
    max-height: ${window.innerHeight - 40}px;
    max-width: ${window.innerWidth}px;
`
const CloseBox = styled.div`
    font-size: 40px;
    text-align: right;
    cursor: pointer;
`
interface ShowModalImageProps {
    filename: string;
    graphicsSubfolder?: string;
    onClose: () => void;
}
const ShowModalImage: React.FC<ShowModalImageProps> = (props) => {
    return (
        <>
            <CloseBox onClick={props.onClose}><i className="far fa-window-close"></i></CloseBox>
            <ModalImageContainer src={formatImageUrl(props.filename, { isCdn: true, sizeDesignator: ImageSizeEnum.magnified })} />
        </>
    )
}
// #region SUBREGION: DateBox
//--------------------------------------------------------
const DateBoxContainer = styled.span<{ isClickable?: boolean }>`
    color: black;
    margin-bottom: 8px;
    cursor: ${props => props.isClickable ? "pointer" : "default"};
    &:hover {
        background-color: ${props => props.isClickable ? app.themes.buttonStyles.backgroundColor : "transparent"};
    }
`
interface DateBoxProps {
    date: DateSupport;
    onClick?: () => void;
}
const DateBox: React.FC<DateBoxProps> = (props) => {

    return (
        <DateBoxContainer isClickable={!!props.onClick} onClick={props.onClick}>
            {props.date.format('MMMM D, YYYY')}
        </DateBoxContainer>
    )
}
// #endregion DateBox
// #endregion RENDER ONE ENTRY WITH THUMB, DATE AND BUTTONS

export default BlogContent;
