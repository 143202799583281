import React from 'react';
import styled from 'styled-components';

import { usePostApi } from '../libs/useDataApiV2';

import api from '../api-url';
import { BlogEntryList, BlogListTypeEnum } from '../interfaces/lib-websites-interfaces';
import { preprocessImages } from '../components/BlogContent';
import IconButton, { ButtonsRow } from '../libs/IconButtonV2';

const AdminContainer = styled.div`
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
`
const TextContainer = styled.div`
    width: 100%;
    height: 600px;
    overflow-y: auto;
`
const TitleContainer = styled.div`
    margin-top: 8px;
    display: flex;
    align-items: center;
    height: 50px;
    font-size: 24px;
    p {
        margin: 0 0 0 6px;
    }
`
const Admin: React.FC = () => {
    const [list, setList] = React.useState<BlogEntryList>();
    const [currIndex, setCurrIndex] = React.useState(-1);
    const [processing, setProcessing] = React.useState(false);

    const { post } = usePostApi();

    const textDivRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
    const titleDivRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
    const idDivRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    const gotList = (result: BlogEntryList) => {
        setList(result),
            setCurrIndex(0);
        setProcessing(true);
    }
    React.useEffect(() => {
           post(api.getBlogEntryList, { list_type: BlogListTypeEnum.recent, start_index: 0, max_count: 100 }, gotList, () => alert("Post error"));
        // post(api.getBlogEntryList, { list_type: BlogListTypeEnum.singleEntry, criteria: "202204080" }, gotList, () => alert("Post error"));
    }, []);

    React.useEffect(() => {
        if (processing && currIndex < list!.entries.length) {
            const title = extractTitle(list!.entries[currIndex].content, list!.entries[currIndex].entry_id, textDivRef.current, titleDivRef.current, idDivRef.current, false);
            console.log(list!.entries[currIndex].entry_id + ": " + title);
            if (!title) {
                extractTitle(list!.entries[currIndex].content, list!.entries[currIndex].entry_id, textDivRef.current, titleDivRef.current, idDivRef.current, true);
                setProcessing(false);
            } else {
                setCurrIndex(state => state + 1);
            }
        }
    }, [processing, currIndex]);


    return (
        <AdminContainer>
            <ButtonsRow>
                <IconButton caption="Next" onClick={() => {
                    setCurrIndex(state => state + 1);
                    setProcessing(true);
                }} />
            </ButtonsRow>
            <TextContainer ref={textDivRef} />
            <TitleContainer>
                <label>ID:</label>
                <p ref={idDivRef}></p>
            </TitleContainer>
            <TitleContainer>
                <label>Title:</label>
                <p ref={titleDivRef}></p>
            </TitleContainer>
        </AdminContainer>
    )
}

const extractTitle = (content: string, id: number, textDiv: HTMLDivElement, titleDiv: HTMLDivElement, idDiv: HTMLDivElement, displayHtml: boolean): string | null => {
    textDiv.innerHTML = content;
    idDiv.innerText = id + '';
    preprocessImages(false, textDiv);
    const spans = textDiv.getElementsByTagName("span");
    // console.log("SPANS:", spans);
    console.log("-------- " + id + " -------------");
    const bolds = textDiv.getElementsByTagName("b");
    for (let i = 0; i < bolds.length; i++) {
        console.log(bolds[i].innerText);
    }
    let title = null;
    for (let i = 0; i < spans.length; i++) {
    //       console.log("span #" + i + ": color=" + spans[i].style.getPropertyValue("color"));
        const color = spans[i].style.getPropertyValue("color");
        if (color === "rgb(192, 0, 0)" || color === "rgb(153, 0, 0)" || color === "rgb(204, 0, 0)") {
      //      console.log("got a red span; childNodes.length=" + spans[i].childNodes.length + ", childElementCount=" + spans[i].childElementCount)
            // for (let j = 0; j < spans[i].childNodes.length; j++) {
            //     console.log("innerText #" + j + ": " + (spans[i].childNodes[j] as HTMLSpanElement).innerText ?? "[empty]");
            // }
            title = spans[i].innerText;
            break;
        }
    }
    if (displayHtml) {
        titleDiv.innerText = title ?? "None";
    }
    return title;
}
const iterateOverDom = (rootNode: Node, callback: (node: Node, userData: Record<string, any>) => boolean, userData: Record<string, any>) => {
    const iterator = document.createNodeIterator(rootNode);
    while (true) {
        const node = iterator.nextNode();
        if (!node) {
            return;
        }
        if (!callback(node, userData)) {
            return;
        }
    }
}

export default Admin;