import React from 'react';
import styled from 'styled-components';

import { useCookies } from '../libs/SamState';
import { BlogCommentPostRecord, BlogEntryRecord, BlogUserRecord } from '../interfaces/lib-websites-interfaces';
import useFormMgr from '../libs/forms/useFormMgr';
import SamForm from '../libs/forms/SamFormV4';
import { StyledErrorText } from '../libs/libSupport';
import { genericApiError, usePostApi } from '../libs/useDataApiV2';
import SamModal, { ModalMessageBoxProps } from '../libs/SamModalV2';

import { FormFieldRecord, FormFieldType, LoginError } from '../interfaces/lib-api-interfaces';

import api from '../api-url';
import app from '../appData';
import useData from './useBlogData';

const SubscribeFormContainer = styled.div`
    font-family: ${app.themes.sansFonts};
    padding: 18px 32px 18px 32px;
    h2 {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 4px;
    }
`
const ThankYouBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px;
    height: 300px;
    padding: 50px;
    font-size: 24px;
`
interface SubscribeFormProps {
    onSubmit: () => void;
}
const SubscribeForm: React.FC<SubscribeFormProps> = (props) => {
    const [message, setMessage] = React.useState<string>();
    const [readyToPost, setReadyToPost] = React.useState(false);
    const [showThankYou, setShowThankYou] = React.useState(false);

    const { getUserInfo, setUserInfo } = useData();
    const forms = useFormMgr();
    const { post } = usePostApi();

    React.useEffect(() => {
        if (readyToPost) {
            const values = forms.getFormValues("subscribe") as BlogUserRecord;
            post(api.subscribe, values, subscribePosted, () => setMessage(genericApiError));
        }
    }, [readyToPost]);
    const subscribePosted = (result: LoginError) => {
        setReadyToPost(false);
        console.log("posted got result:", result)
        if (result === LoginError.noError) {
            setShowThankYou(true);
        } else {
            setMessage("This handle is being used with a different email. Please change your handle or your email and resubmit.");
        }
    }

    const fields: FormFieldRecord[] = [
        { name: "author_handle", label: "Display name to show on your comments", validator: { required: true, maxLength: 50 } },
        { name: "email", label: "Email Address (will NOT be published)", validator: { required: true, maxLength: 150 } },
        { name: "remember", label: "Save my info in this browser to make it easier to post future comments", type: FormFieldType.checkbox }
    ];

    const userInfo = getUserInfo();
    const initialValues = {} as BlogCommentPostRecord;
    if (userInfo) {
        initialValues.author_handle = userInfo.author_handle;
        initialValues.email = userInfo.email;
        initialValues.remember = true;
    }

    const handleSubmit = (values: Record<string, any> | null, id: string) => {
        setMessage(undefined);
        if (!values) {
            props.onSubmit();
        } else {
            setReadyToPost(true);
            if (values.remember) {
                setUserInfo({ author_handle: values.author_handle, email: values.email, notify: true, subscribe: true });
            }
        }
    }

    if (showThankYou) {
        return (
            <SamModal closeBarClicked={props.onSubmit}>
                <ThankYouBox>Thank you for subscribing to the River. You will receive an email when Karina posts a new entry.</ThankYouBox>
            </SamModal>

        )
    } else {
        return (
                <SubscribeFormContainer>
                    <h2>Subscribe to receive an email when Karina creates a new post</h2 >
                    {message && <StyledErrorText>{message}</StyledErrorText>
                    }
                    <SamForm forms={forms} id="subscribe" fields={fields} createSubmitButton={true} createCancelButton={true} handleSubmit={handleSubmit} />
                </SubscribeFormContainer >
        )
    }
}
export default SubscribeForm;
