import React, { FieldsetHTMLAttributes } from 'react';
import deepcopy from 'deepcopy';

import { FormFieldRecord, CustomValidatorCallback, FormFieldType } from '../../interfaces/lib-api-interfaces';

export const isValidEmail = (email: string) => {
    return email && (/(.+)@(.+){2,}\.(.+){2,}/).test(email);
}

// for example of using forms.clear() with page reload see infoContentEditor in libs/dashboard

export interface UseFormMgr {
    clear: () => void;
    getAllFields: () => Record<string, FormFieldRecord[]>;
    getValue: (formId: string, fieldName: string) => any;
    setValue: (formId: string, fieldName: string, value: any) => Record<string, any>;
    getFormValues: (formId: string) => Record<string, any>;
    setFormFieldsAndValues: (formId: string, formFields: FormFieldRecord[], formValues: Record<string, any>) => void;
    updateFormValues: (formId: string, formValues: Record<string, any>) => void;
    getFormFields: (formId: string) => FormFieldRecord[];
    getField: (formId: string, fieldName: string) => FormFieldRecord | undefined;
    validateForm: (formId: string, customValidator?: CustomValidatorCallback) => boolean;
    getError: (formId: string, fieldName: string) => string;
    deleteError: (formId: string, fieldName: string) => void;
    getAllValues: () => void;
    clearCoupon: () => void;
}
interface FormState {
    state: Record<string, Record<string, string>>;
}
const useFormMgr = () => {
    const [values, setValues] = React.useState<FormState>({} as FormState);
    const [fields, setFields] = React.useState<Record<string, FormFieldRecord[]>>({});
    const [errors, setErrors] = React.useState<Record<string, Record<string, string>>>({});

    const clear = () => {
        setValues({} as FormState);
        setFields({});
        setErrors({});
    }
    const getAllFields = (): Record<string, FormFieldRecord[]> => {
        return fields;
    }
    const getValue = (formId: string, fieldName: string): any => {
        if (!values[formId as keyof FormState]) {
            return '';
        }
        return values[formId as keyof FormState][fieldName];
    }
    // returns updated form values object
    const setValue = (formId: string, fieldName: string, value: any): Record<string, any> => {
        const newValues = deepcopy(values) as FormState;
        const newFormValues = newValues[formId as keyof FormState] ? newValues[formId as keyof FormState] : {};
        newFormValues[fieldName] = value;
        newValues[formId as keyof FormState] = newFormValues;
        setValues(newValues);
        return newFormValues;
    }
    const getFormValues = (formId: string): Record<string, any> => {
    //    console.log("getFormValues(" + formId + "):", values)
        return values[formId as keyof FormState];
    }
    // eliminate all null values so controlled forms will work
    const cleanValues = (formId: string, formValues: Record<string, any>, formFields: FormFieldRecord[]): Record<string, any> => {
        const clean = {} as Record<string, any>;
        formFields.map(field => {
            if (!formValues[field.name]) {
                if (field.type === FormFieldType.checkbox) {
                    clean[field.name] = false;
                } else if (field.type === FormFieldType.int || field.type === FormFieldType.money) {
                    clean[field.name] = 0;
                } else {
                    clean[field.name] = '';
                }
            } else {
                clean[field.name] = formValues[field.name];
            }
        });
        return clean;
    }
    const setFormFieldsAndValues = (formId: string, formFields: FormFieldRecord[], formValues: Record<string, any>) => {
     //        console.log("setFormFieldAndValues for " + formId + ", fields:", formFields); console.log("values:", formValues)
        const newFields = { ...fields };
        newFields[formId] = formFields;
        setFields(newFields);
        const newValues = deepcopy(values) as FormState;
        newValues[formId as keyof FormState] = cleanValues(formId, formValues, formFields);
        setValues(newValues);
    //    console.log("setting values:", newValues)
    }
    const updateFormValues = (formId: string, formValues: Record<string, any>) => {
        const newValues = deepcopy(values) as FormState;
        newValues[formId as keyof FormState] = cleanValues(formId, formValues, getFormFields(formId));
        setValues(newValues);
    }
    const getFormFields = (formId: string): FormFieldRecord[] => {
        return fields[formId];
    }
    const getField = (formId: string, fieldName: string): FormFieldRecord | undefined => {
        return getFormFields(formId).find(field => field.name === fieldName);
    }
    const setFormErrors = (formId: string, formErrors: Record<string, any>) => {
        const newErrors = { ...errors };
        newErrors[formId] = formErrors;
        setErrors(newErrors);
    }
    const getError = (formId: string, fieldName: string): string => {
        if (!errors[formId]) {
            return '';
        }
        return errors[formId][fieldName];
    }
    const deleteError = (formId: string, fieldName: string) => {
        if (errors[formId] && fieldName in errors[formId]) {
            delete errors[formId][fieldName];
        }
    }
    // return true if form validates
    const validateForm = (formId: string, customValidator: CustomValidatorCallback | null = null): boolean => {
        const newErrors = validateAllFields(formId, getFormFields(formId), customValidator);
        setFormErrors(formId, newErrors);
        return !Object.keys(newErrors).length;
    }
    const validateAllFields = (formId: string, fields: FormFieldRecord[], customValidator: CustomValidatorCallback | null): Record<string, any> => {
        const newErrors: Record<string, any> = {};
        fields.forEach((field) => {
            let errorMessages = validateField(formId, field, customValidator);      // this combines standard validation with custom user validation (if any)
            if (errorMessages.length > 0) {
                newErrors[field.name] = errorMessages[0];
            }
        });
        return newErrors;
    }

    // return an array of error messages
    // return empty array if no errors
    const validateField = (formId: string, field: FormFieldRecord, customValidator: CustomValidatorCallback | null) => {
        let messages = [];
        const v = field.validator;
        let value = getValue(formId, field.name);
        // start with caller provided validators
        if (v && v.required && !value) {
            messages.push(field.label + " is required");
            return messages;
        }
        if (v && v.minLength && (!value || value.length < v.minLength)) {
            messages.push("Must be at least " + v.minLength + " characters");
        }
        if (field.name.includes("email") && value && !isValidEmail(value)) {
            messages.push("Please enter a valid email address");
        }
        if (messages.length === 0 && customValidator) {
            const msg = customValidator(field.name, getFormValues(formId));
            if (msg) {
                messages.push(msg);
            }
        }
        return messages;
    }

    // for debugging:
    const getAllValues = () => {
        return values;
    }
    const clearCoupon = () => {
        /*
        const newValues = { ...values };
        console.log("newValues:", newValues)
        const newFormValues = newValues["extras"] ? newValues["extras"] : {};
        console.log("newFormValues:", newFormValues)
        newFormValues["coupon"] = '';
        newValues["extras"] = newFormValues;
        console.log("setting values to:", newValues)
        */
    }
    return {
        clear, getAllFields, getValue, setValue, getFormValues, setFormFieldsAndValues, updateFormValues, getFormFields, getField, validateForm, getError, deleteError, getAllValues, clearCoupon
    }
}
export default useFormMgr;
