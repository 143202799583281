import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { store } from './libs/SamState';
import Routes from './Routes';

// 👇️ IMPORTANT: use correct ID of your root element
// this is the ID of the div in your index.html file
const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
window.addEventListener("error", (event) => {
   // log.textContent = `${log.textContent}${event.type}: ${event.message}\n`;
    console.log(event);
  });
// 👇️ if you use TypeScript, add non-null (!) assertion operator
// const root = createRoot(rootElement!);


root.render(
    <Provider store={store}>
        <Routes />
    </Provider>
);

