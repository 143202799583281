import { MenuItemData } from "./interfaces/lib-api-interfaces";

const shoppingCart = false;
const isDashboard = false;
const allGraphicsLocal = true;      // for development only

const targetDomain = "river.karinaland.com";        // this is the domain targeted by the dashboard (if this is a dashboard; if not it is ignored)

const adminCustId = 1;
const productGraphicsBaseUrl = (window.location.hostname === "localhost" ? process.env.PUBLIC_URL : ("https://" + targetDomain)) + "/graphics";

const pageSize = 10;

const mainMenu: MenuItemData[] = [
  { caption: "KARINALAND RIVER", href: "/" },
  { caption: "ABOUT", href: "/about" },
  { caption: "SUBSCRIBE", href: "/subscribe" },
  { caption: "MEMORIAL MURAL", href: "/mural" }
];

// themes is a standard object for passing to custom components
/* typical uses:
    backColor10 - input fields, forecolor when background is reversed
    backColor25 - buttons, logo, dropdown menus
    backColor50 - captions on grids (e.g., store near you)
*/
//const logoFont = "Gruppo,Arimo,Arial,Helvetica,sans-serif";
const sansFonts = "Arimo,Arial,Helvetica,sans-serif";
const goldHoverColor = "#be8602";
// const lightGold = "#c9ac63";
const lightGold = "#c5ac6d";
const themes = {
  serifFonts: "'Times New Roman',Times,George,Palatino,Garamond",
  sansFonts: sansFonts,
  foreColor: "rgb(107,107,107)",
  linkColor: "#b58936",
//  backShadeColor: "rgba(255, 255, 255, 0.65)",
  backShadeColor: "white",
  backColor50: "#d3d584",  //  583 at 50%
  backColor25: "#a0852c",  //  this is the button back color (currently gold)
  backColor10: "#f6f8e6", // 583 at 10%
  navbarFont: sansFonts,
 // navbarHoverColor: goldHoverColor,
  navbarHoverColor: "yellow",
  defaultFonts: sansFonts,
  buttonStyles: {
    backgroundColor: "rgb(240, 240, 240)",
    color: "black",
    fontWeight: "normal",
    border: "none",
    borderRadius: "10px"
  },
  buttonHoverStyles: {
    color: "black",
    backgroundColor: lightGold
  }
}
const riverThemes = {
  titleBackColor: "#4e6d1f",
  logoImage: "river-logo.png",
  bannerImageFilename: "fairybell-bee-fade-cropped.png",
  bannerBackColor: "#99a651",       // olive
  contentBackColor: "white",
  sidebarBackColor: "#cfd6ad",
  sidebarColor: "#996f3f",
  indexTitleColor: "black",           // rust (from blogspot.com)
  indexLabelColor: "black",
  lightGold: lightGold,
  postFontFamily: sansFonts,
  postFontSize: 20,
  postFontLineHeight: 30,
  commentFontFamily: "Outfit,Arial,Helvetica,sans-serif"
}
const positions = {
  isSidebarDisplayedKey: "sidebar",
  scrollToCommentsKey: "scroll",
  maxContentWidth: 920,   // starts here and gets narrower as screen decreases; current width kept in global context
  suppressSidebarWidth: 650,
  sidebarWidth: 238
}

export default {
  pageSize,
  mainMenu,
  allGraphicsLocal,
  shoppingCart,
  isDashboard,
  targetDomain,
  adminCustId,
  productGraphicsBaseUrl,
  themes,
  riverThemes,
  positions
}