import path from "path-browserify";
import { insert } from "./libSupport";

import { cdnDomain, ImageFileOptions, ImageRecord } from "../interfaces/lib-api-interfaces";

import app from '../appData';

// use this if image could be video or blob; (use formatImageUrl if filename only)
export const formatImageOrVideoSrcWithFile = (image: ImageRecord, fileOptions?: ImageFileOptions): string => {
    if (image.stream_thumb_src) {
        return image.stream_thumb_src;
    }
    return (image.file ? URL.createObjectURL(image.file) : formatImageUrl(image.filename!, fileOptions));
}
// if size is passed it is inserted with underscore before file extension
// e.g.: insert("hello.jpg", "f") -> "hello_f.jpg"
// dashboard uses targetDomain as root, otherwise use app.productGraphicsBaseUrl (PUBLIC folder if running locally)
// app.targetDomain = "laurainbocrystals.com" or "sam.bond" etc. (if this is dashboard the targetDomain is to the production site)
// if graphics are on cdn assume that they will be read off remote server even if this is dev server
export const formatImageUrl = (filename: string, fileOptions?: ImageFileOptions) => {
    if (!filename) {
        return '';
    }
    const isDev = window.location.hostname === "localhost";
//    const isCdn = (isDev && app.allGraphicsLocal) ? false : fileOptions?.isCdn;
    const isCdn = false;
    // domainPart takes us through .com or .lol etc.
    const domainPart = isCdn ? ("https://" + cdnDomain) : (isDev ? process.env.PUBLIC_URL : ("https://" + app.targetDomain));
    const subFolder = fileOptions?.graphicsSubfolder ? (fileOptions.graphicsSubfolder) : '';
    const graphicsFolder = isCdn ? "cdn" : "graphics";
    const cdnTargetDomain = isCdn ? app.targetDomain : '';
    const filenamePart = (fileOptions?.sizeDesignator && filename.length > 4) ? insert(filename, filename.length - 4, "_" + fileOptions.sizeDesignator) : filename;
    const url = domainPart + "/" + path.join(graphicsFolder, cdnTargetDomain, subFolder, filenamePart);
    //  console.log({ isDev, domainPart, subFolder, graphicsFolder, cdnTargetDomain, url });
    return url;
}
export const formatYoutubeThumbUrl = (id: string): string => {
    return "https://img.youtube.com/vi/" + id + "/1.jpg";
}
export const formatVimeoApiUrl = (id: string): string => {
    return "http://vimeo.com/api/v2/video/" + id + ".json";
}
// remove domain info and sizing info; return filename itself
export const deformatImageUrl = (url: string): string => {
    const nameStartPosn = url.lastIndexOf('/') + 1;
    let filename = url.substring(nameStartPosn);
    return filename.replace("_f.", '.').replace("_m.", '.');
}
