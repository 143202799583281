import React from 'react';
import SubscribeForm from '../components/SubscribeForm';
import { useNavigate } from 'react-router-dom';

import '../reset.css';
import MasterPage from '../components/MasterPage';

const Subscribe: React.FC = () => {
    const navigate = useNavigate();
    return (
        <MasterPage>
            <SubscribeForm onSubmit={() => navigate("/")} />
        </MasterPage>
    )
}
export default Subscribe;