import React from 'react';
import styled from 'styled-components';

import MasterPage from '../components/MasterPage';

const text: string[] = [
    '"Courage is the most important of all the virtues,',
    "because without courage you can't practice any",
    "other virtue consistently. You can practice",
    "any virtue erratically, but nothing consistently",
    'without courage." Maya Angelou'
];

const HeaderRow = styled.div`
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    width: 100%;
    padding: 32px;
`
const AboutCaption = styled.p`
    font-size: 24px;
    font-weight: bold;
`
const TextContainer = styled.div`
    font-size: 18px;
    text-align: center;
`
const TitleText = styled.p`
    line-height: 150px;
`
const TextRow = styled.p`
    line-height: 40px;
`
const About: React.FC = () => {
    return (
        <MasterPage>
            <>
                <HeaderRow>
                    <AboutCaption>about</AboutCaption>
                </HeaderRow>
                <TextContainer>
                    <TitleText>Karinaland</TitleText>
                    {text.map(row => {
                        return <TextRow key={row}>{row}</TextRow>
                    })}
                </TextContainer>
            </>
        </MasterPage>
    )
}
export default About;
