import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGlobalContext } from '../libs/SamState';
import { iPadMinWidth } from '../libs/libSupport';
import { apiStateKey } from '../libs/useDataApiV2';
import Spinner from '../libs/Spinner';
import { formatImageUrl } from '../libs/ImageFormatter';
import Sidebar from './Sidebar';

import app from '../appData';
import api from '../api-url';

import '../reset.css';
import '../app.css';

const MasterContainerHolder = styled.div<{ marginLeft: number }>`
    margin-left: ${props => props.marginLeft}px;
    display: flex;
    justify-content: center;
`
const MasterContainer = styled.div`
    position: relative;
    background-color: ${app.riverThemes.bannerBackColor};
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    color: ${app.themes.foreColor};
    a:link {
        text-decoration: underline; color: ${app.themes.linkColor};
    }
    a:visited {
        text-decoration: underline; color: ${app.themes.linkColor};
    }
    a:hover {
        font-style: italic; font-weight: bold; text-decoration: underline; color: ${app.themes.linkColor};
        font-style: italic; font-weight: bold; text-decoration: underline; color: ${app.riverThemes.lightGold};
    }
`
const BannerAndContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    font-family: ${app.riverThemes.postFontFamily};
    font-size: ${app.riverThemes.postFontSize}px;
    line-height: ${app.riverThemes.postFontLineHeight}px;
`
const StyledImage = styled.img`
    width: 100%;
    height: auto;
`
const LogoImage = styled.img`
    margin-top: -50%;
    z-index: 1000;
    max-width: 500px;
    width: 100%;
    height: auto;
    cursor: pointer;
`
const TitleBox = styled.div<{ posnPct: number }>`
    margin-top: ${props => props.posnPct}%;
    padding: 6px 16px;
    font-size: 18px;
    line-height: 24px;
    background-color: ${app.riverThemes.titleBackColor};
    color: white;
    text-align: center;
    font-weight: bold;
    border-radius: 15px;
`
const ContentContainer = styled.div`
    margin-top: 2%;
    background-color: ${app.themes.backShadeColor};
    max-width: ${app.positions.maxContentWidth}px;
    width: 90%;
    z-index: 1000;
    margin-bottom: 50px;
`
const TestModeText = styled.p`
    color: white;
    font-weight: bold;
    text-align: center;
`
/*
const LogoText = styled.p<{ width: number; left: number }>`
    position: absolute;
    top: ${app.positions.logoTop}px;
    width: ${props => props.width}px;
    left: ${props => props.left}px;
    font-family: ${app.riverThemes.logoFont};
    font-weight: 500;
    font-size: 44px;
    text-align: center;
    color: white;
    cursor: pointer;
    @media screen and (max-width: 712px) {
        font-size: 40px;
    }
`
*/
interface MasterPageProps extends PropsWithChildren {
    title?: string;
}
const MasterPage: React.FC<MasterPageProps> = (props) => {
    const [forceRerender, setForceRerender] = React.useState(false);
    const [titleBoxPosn, setTitleBoxPosn] = React.useState(0);

    const navigate = useNavigate();
    const { getContext, setContext } = useGlobalContext();

    React.useEffect(() => {
        window.addEventListener('resize', () => {
            setForceRerender(state => !state);      // call this so a rerender adjusts all sizes
            //     console.log("innerwidth=" + window.innerWidth)
        });
    }, []);
    React.useEffect(() => {
        const sidebarWidth = getContext(app.positions.isSidebarDisplayedKey) ? app.positions.sidebarWidth : 0;
        const matchWidth = 900 + sidebarWidth;
        const posn = window.matchMedia("(max-width: " + matchWidth + "px)").matches ? 1 : 6;
        if (posn !== titleBoxPosn) {
            setTitleBoxPosn(posn);
        }
        if (window.matchMedia("(max-width: " + app.positions.suppressSidebarWidth + "px)").matches && sidebarWidth) {
            setContext(app.positions.isSidebarDisplayedKey, false);
        }
    }, [window.innerWidth]);

    const location = useLocation();
    React.useEffect(() => {
        if (window.location.hostname !== "localhost") {
            const paq = (window as any)._paq;
            paq.push(['setCustomUrl', location.pathname]);
            paq.push(['setDocumentTitle', location.pathname]);
            paq.push(['trackPageView']);
        }
    }, [location]);

    const apiState = getContext(apiStateKey);
    const apiStateElapsed = apiState ? (Date.now() - apiState) : 0;
    if (apiStateElapsed) {
        // check back in 1 second to see if we are still busy; if so we can show spinner
        setTimeout(() => {
            //     console.log("setForceRerender: apiStateElapsed=" + apiStateElapsed + ", apiState=" + apiState)
            setForceRerender(state => !state);
        }, 1000);
    }

    //   console.log("MASTERPAGE")
    const isSmallScreen = window.matchMedia("(max-width: " + app.positions.suppressSidebarWidth + "px)").matches;
    const isSidebarDisplayed = getContext(app.positions.isSidebarDisplayedKey);
    const leftMargin = isSmallScreen || !isSidebarDisplayed ? 0 : app.positions.sidebarWidth;

    return (
        <MasterContainerHolder marginLeft={leftMargin}>
            <MasterContainer>
                <Sidebar />
                <BannerAndContentContainer>
                    {getContext("testMode") && <TestModeText>TEST MODE</TestModeText>}
                    <StyledImage src={formatImageUrl(app.riverThemes.bannerImageFilename)} />
                    <LogoImage src={formatImageUrl(app.riverThemes.logoImage)} onClick={() => navigate("/")} />
                    <NavBar />
                    {props.title &&
                        <TitleBox posnPct={titleBoxPosn}>{props.title}</TitleBox>
                    }
                    <ContentContainer>
                        {props.children}
                    </ContentContainer>
                    {(apiStateElapsed > 1000) && <Spinner />}
                </BannerAndContentContainer>
            </MasterContainer>
        </MasterContainerHolder>
    );
}
//--------------------------------------------------------
const NavContainer = styled.div`
    margin-top: -1%;
    z-index: 2000;
    display: flex;
    width: 100%;
    max-width: 600px;
    justify-content: space-between;
    color: white;
    font-size: 16px;
    line-height: 32px;
    font-family: ${app.themes.navbarFont};
    @media (max-width: ${iPadMinWidth}px) {
        font-size: 12px;
    }
    p {
        cursor: pointer;
        &:hover {
            color: ${app.themes.navbarHoverColor};
            font-weight: bold;
            font-style: italic;
        }
    }
`
const NavBar: React.FC = () => {
    const navigate = useNavigate();

    const itemClicked = (href: string) => {
        if (href.startsWith("https")) {
            window.open(href, '_blank');
        } else {
            navigate(href);
        }
    }
    return (
        <NavContainer>
            {app.mainMenu.map(item => {
                return (
                    <p key={item.caption} onClick={() => itemClicked(item.href!)}>{item.caption}</p>
                )
            })}
        </NavContainer>
    )
}
export default MasterPage;
