import React from 'react';
import dayjs from 'dayjs';

import { useCookies, useGlobalContext, useSessionStore } from '../libs/SamState';
import { useFetchApi, usePostApi } from '../libs/useDataApiV2';

import { BlogArchiveData, BlogEntryList, BlogEntryListCriteria, BlogListTypeEnum, BlogUserRecord } from '../interfaces/lib-websites-interfaces';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import api from '../api-url';
import app from '../appData';

// pageSize must be defined in appData or it defaults to 10
const useData = () => {
    const [postCriteria, setPostCriteria] = React.useState<BlogEntryListCriteria>();    // use strictly to trigger a post
    const [fetchingArchive, setFetchingArchive] = React.useState(false);        // used to trigger getch for archive
    const [fetchCompleted, setFetchCompleted] = React.useState<() => void>();

    const { getContext, setContext } = useGlobalContext();
    const { getSessionStore, setSessionStore } = useSessionStore();
    const { getCookie, setCookie } = useCookies();
    const { post } = usePostApi();
    const { fetch } = useFetchApi();
    const navigate = useNavigate();

    // break BlogEntryList into 2 objects: criteria and entries so they can be retrieved separately from store
    const entriesKey = "entryList";     // for store
    const criteriaKey = "criteria";     // parsed directly from url
    const archiveKey = "archive";
    const userInfoKey = "userInfo";     // stored in cookie
    const hitsKey = "hits";         // stored in session store so paging can continue through refresh

    const params = useParams();
    const [searchParams] = useSearchParams();

    const pageSize = app.pageSize ?? 10;


    React.useEffect(() => {
        if (postCriteria) {
            post(api.getEntryList, postCriteria, setListData, setApiError);
        }
    }, [postCriteria]);
    const setListData = (data: BlogEntryList) => {
        setPostCriteria(undefined);
        setContext(entriesKey, data);
        setSessionStore(hitsKey, data.criteria.hits ?? null);
        fetchCompleted && fetchCompleted();     // caller passed this with call to updateUrl -- allows for scroll to top when data comes in
    }

    React.useEffect(() => {
        if (fetchingArchive) {
            fetch(api.getArchiveData, null, setArchiveData, setApiError);
        }
    }, [fetchingArchive]);

    const setArchiveData = (data: BlogArchiveData) => {
        setFetchingArchive(false);
        setContext(archiveKey, data);
    }

    const setApiError = (error: string) => {
        setContext("fatalError", true);
    }

    /* url format:
        id/{nnnnnnn}
        /archive/{season}/{year}?args
        /topic/{topicLabel}?args
        /search/{term}?args
        (any other retrieves most recent entries)

        args:
        ?s={start_index}    // default to 0
    */

    // call this whenever url changes (user wants a post, wants a topic, pages data, etc.)
    const updateUrl = (completed: () => void) => {
        const currentCriteria = getContext(criteriaKey) as BlogEntryListCriteria;
        const locationCriteria = urlToCriteria();
        console.log("currentCriteria:", currentCriteria, "locationCriteria:", locationCriteria)
        if (currentCriteria && currentCriteria.list_type === locationCriteria.list_type && currentCriteria.criteria === locationCriteria.criteria) {
            // basic data is same (i.e., topic/archive etc.) so see if we want new page
            if (currentCriteria.start_index !== locationCriteria.start_index) {
                // only page number changed so load existing hits list into criteria for passing to api
                locationCriteria.hits = getSessionStore(hitsKey);
            } else {
                // nothing has changed
                return;
            }
        }
        // at this point we know we need to call api; either for a new page or first page
        // whether hits is defined will determine whether the api gets a new page or first page
        locationCriteria.page_size = pageSize;
        console.log("setting post criteria to:", locationCriteria)
        setPostCriteria(locationCriteria);
        setContext(criteriaKey, locationCriteria);
        setFetchCompleted(completed);
    }

    const getArchiveData = (): BlogArchiveData | null => {
        // return null if data not in memory; if not go ahead and fetch it
        if (fetchingArchive) {
            return null;
        }
        const data = getContext(archiveKey) as BlogArchiveData | null;
        if (!data) {
            setFetchingArchive(true);       // fetch after render
        }
        return data;
    }

    // return criteria suitable for passing to api
    // return null if url is invalid or empty
    const urlToCriteria = (): BlogEntryListCriteria => {
        let criteria: BlogEntryListCriteria | null = null;
        if (params.entryId) {
            criteria = { list_type: BlogListTypeEnum.singleEntry, criteria: params.entryId };
        } else if (params.season) {
            criteria = { list_type: BlogListTypeEnum.archive, criteria: params.season + "/" + params.year };
        } else if (params.topicLabel) {
            criteria = { list_type: BlogListTypeEnum.topic, criteria: params.topicLabel };
        } else if (params.topicId) {
            criteria = { list_type: BlogListTypeEnum.topic, criteria: params.topicId };
        } else if (params.term) {
            criteria = { list_type: BlogListTypeEnum.search, criteria: decodeURI(params.term) };
        } else {
            criteria = { list_type: BlogListTypeEnum.recent };
        }

        if (searchParams.get("s")) {
            const index = parseInt(searchParams.get("s") as string);
            criteria.start_index = isNaN(index) ? 0 : index;
        }
        return criteria;
    }

    const getEntryList = (): BlogEntryList | null => {
        return getContext(entriesKey) as BlogEntryList | null;
    }

    const getServerEntryCount = (): number => {
        const hits = getSessionStore(hitsKey) as number[];
        return hits ? hits.length : 0;
    }

    // called brom BlogContent upon paging
    const setListIndex = (index: number) => {
        let currIndex = parseInt(searchParams.get("s") ?? "0");
        if (isNaN(currIndex)) {
            currIndex = 0;
        }
        if (currIndex !== index) {
            searchParams.set("s", index + '');
            const parts = window.location.pathname.split('?');
            const newPath = parts[0] + '?' + searchParams.toString();
     //       console.log("navigating to " + newPath);
            navigate(newPath);
        }
    }

    // return message to display above rendered entries ("Showing...")
    const formatListDescription = (): string => {
        let title = '';
        const currCriteria = urlToCriteria();
        switch (currCriteria?.list_type) {
            case BlogListTypeEnum.archive:
                title = "Showing posts from " + currCriteria.criteria!.replace('/', ' ');
                break;
            case BlogListTypeEnum.search:
                if (!getSessionStore(hitsKey)?.length) {
                    title = 'No results found for "';
                } else {
                    title = 'Showing posts matching the search for "'
                }
                 title += currCriteria.criteria + '"';
                break;
            case BlogListTypeEnum.topic:
                title = "Showing posts matching the topic of " + currCriteria.criteria;
                break;
        }
        return title;
    }

    const getUserInfo = (): BlogUserRecord | null => {
        return getCookie(userInfoKey);
    }
    const setUserInfo = (info: BlogUserRecord) => {
        // passing 0 in this case means store info indefinitely
        setCookie(userInfoKey, info, 0);
    }

    return {
        updateUrl,
        getArchiveData,
        formatListDescription,
        getEntryList,
        getServerEntryCount,
        setListIndex,        // shortcut to force paging
        getUserInfo,
        setUserInfo
    }
}

export default useData;