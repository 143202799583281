import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const SearchContainer = styled.input<{ placeholderColor: string }>`
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${props => props.placeholderColor};
        opacity: .7; /* Firefox */
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: ${props => props.placeholderColor};
    }
    &::-ms-input-placeholder { /* Microsoft Edge */
        color: ${props => props.placeholderColor};
    }
`
interface SearchBoxProps {
    style?: Record<string, string>;
    isExpandable?: boolean;
}
const SearchBox: React.FC<SearchBoxProps> = (props) => {
    const navigate = useNavigate();

    const doSearch = (key: string) => {
        navigate("/search/" + encodeURI(key));
    }
    const clicked = (e: React.MouseEvent<HTMLInputElement>) => {
        if (props.isExpandable) {
            const target = e.target as HTMLInputElement;
            target.style.width = props.style?.width ?? '';
            target.style.fontSize = props.style?.fontSize ?? "16px";
            target.style.outline = "none";
        }
    }
    const inputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            const target = e.target as HTMLInputElement;
            if (target.value) {
                doSearch(target.value);
            }
        }
    }

    const style = props.style ? { ...props.style } : {};
    if (props.isExpandable) {
        style.width = "64px";
        style.fontSize = "14px";
    }
    return (
        <SearchContainer style={style} placeholderColor={props.style?.color? props.style.color : "black"} placeholder="SEARCH" onKeyDown={inputKeyDown} onClick={clicked} />
    )
}
export default SearchBox;