import React from 'react';
import styled from 'styled-components';

import { DateSupport } from '../libs/api-shared/date-support';
import IconButton, { ButtonsRow } from '../libs/IconButtonV2';
import { formatImageUrl } from '../libs/ImageFormatter';
import RenderCleanHtml from '../libs/RenderCleanHtml';
import { IconButtonAlignEnum } from '../interfaces/lib-react-interfaces';
import { BlogCommentPostRecord, BlogEntryRecord } from '../interfaces/lib-websites-interfaces';
import useFormMgr from '../libs/forms/useFormMgr';
import SamForm from '../libs/forms/SamFormV4';
import { StyledErrorText } from '../libs/libSupport';
import { genericApiError, usePostApi } from '../libs/useDataApiV2';
import SamModal from '../libs/SamModalV2';

import { FormFieldRecord, FormFieldType, LoginError } from '../interfaces/lib-api-interfaces';

import api from '../api-url';
import app from '../appData';
import useData from './useBlogData';

// #region COMMENTS
//--------------------------------------------------------
const CommentsContainer = styled.div`
    margin-left: 80px;
    margin-right: 80px;
    font-family: ${app.riverThemes.commentFontFamily};
`
const Comment = styled.div<{ indent: number }>`
    margin-bottom: 40px;
    margin-left: ${props => props.indent}px;
`
const CommentCircle = styled.img`
    float: left;
    margin: 16px;
    margin-left: 0;
`
const CircleAuthorPubDate = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    height: 36px;
    p {
        margin: 0;
    }
`
const PubDateText = styled.span`
    font-weight: normal;
    margin-left: 16px;
`
interface CommentsProps {
    entry: BlogEntryRecord;
    scrollIntoView: boolean;
    circleClicked: () => void;
}
const Comments: React.FC<CommentsProps> = (props) => {
    const [showCommentForm, setShowCommentForm] = React.useState(0);    // show form after comment with this index (-1 if showing at end)
    const [doScroll, setDoScroll] = React.useState(false);

    const commentsRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    React.useEffect(() => {
        if (props.scrollIntoView) {
            setDoScroll(true);
        }
    }, []);

    React.useEffect(() => {
        if (doScroll) {
            setDoScroll(false);
        //    document.getElementById("comments")!.scrollTo({ top: 1000, left: 0, behavior: "smooth" });
            setTimeout(() => commentsRef.current.scrollIntoView({ behavior: "smooth", block: "end" }), 100);
        }
    }, [doScroll]);

    const commentClicked = (index: number) => {
        setShowCommentForm(index);
        window.scrollBy(0, 400);
    }

    return (
        <CommentsContainer ref={commentsRef}>
            {props.entry.comments && props.entry.comments.length &&
                props.entry.comments.map((comment, index) => {
                    return (
                        <Comment key={index} indent={comment.reply_to ? 64 : 0}>
                            <CircleAuthorPubDate>
                                <CommentCircle onClick={props.circleClicked} src={formatImageUrl("k-in-circle50.png")} />
                                <p>{comment.author_handle}</p>
                                <PubDateText>{DateSupport.fromString(comment.pub_date).format('g')}</PubDateText>
                            </CircleAuthorPubDate>
                            <RenderCleanHtml text={comment.content} />
                            {showCommentForm === index + 1 ?
                                <CommentForm entry_id={props.entry.entry_id} replyTo={comment.reply_to} onSubmit={() => setShowCommentForm(0)} />
                                : (
                                    <ButtonsRow autoStyleButtons={true} marginTop={8} align={IconButtonAlignEnum.center} height={36}>
                                        <IconButton caption="Reply" onClick={() => commentClicked(index + 1)} />
                                    </ButtonsRow>
                                )
                            }
                        </Comment>
                    )
                })}
            {showCommentForm === -1 ?
                <CommentForm entry_id={props.entry.entry_id} onSubmit={() => setShowCommentForm(0)} />
                : <IconButton caption="Add a comment" onClick={() => commentClicked(-1)} />
            }
        </CommentsContainer>
    )
}
//------------------------------------------------------------------------------
const CommentFormTitleText = styled.p`
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 2px;
`
const ScrollRefDiv = styled.div`
    content: "";
    height: 1px;
    width: 1px;
    color: transparent;
`
const ThankYouBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 350px;
    height: 200px;
    padding: 30px;
    font-size: 18px;
    line-height: 28px;
    font-family: ${app.themes.sansFonts};
`
interface CommentFormProps {
    entry_id: number;
    replyTo?: string;
    onSubmit: () => void;
}
const CommentForm: React.FC<CommentFormProps> = (props) => {
    const [message, setMessage] = React.useState<string>();
    const [readyToPost, setReadyToPost] = React.useState(false);
    const [showThankYou, setShowThankYou] = React.useState(false);
    const [doScroll, setDoScroll] = React.useState(false);

    const forms = useFormMgr();
    const { post } = usePostApi();
    const { getUserInfo, setUserInfo } = useData();

    const scrollRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
    const titleRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    React.useEffect(() => {
        if (doScroll) {
            setDoScroll(false);
            scrollRef.current.scrollIntoView();
            titleRef.current.scrollIntoView();
        }
    }, [doScroll]);

    React.useEffect(() => {
        setDoScroll(true);
    }, []);

    React.useEffect(() => {
        if (readyToPost) {
            const values = forms.getFormValues("comment") as BlogCommentPostRecord;
            values.entry_id = props.entry_id;
            values.reply_to = props.replyTo;
            values.pub_date = DateSupport.now().format();
            post(api.postComment, values, commentPosted, () => setMessage(genericApiError));
        }
    }, [readyToPost]);
    const commentPosted = (result: LoginError) => {
        setReadyToPost(false);
        if (result === LoginError.noError) {
            setShowThankYou(true);
        } else {
            setMessage("This handle is being used with a different email. Please change your handle or your email and resubmit.");
        }
    }

    const fields: FormFieldRecord[] = [
        {
            name: "content", label: '', type: FormFieldType.multiLine, inputHeight: 100, validator: { maxLength: 1000 },
            placeholder: "Enter your comment here (up to 1000 characters)", multiLineBackColor: "white"
        },
        { name: "author_handle", label: "Display name to show on your comments", validator: { required: true, maxLength: 50 } },
        { name: "email", label: "Email Address (will NOT be published)", validator: { required: true, maxLength: 150 } },
        { name: "notify", label: "Send me an email when my comment goes live", type: FormFieldType.checkbox },
        { name: "subscribe", label: "Subscribe to the River (send me an email when Karina creates a new post)", type: FormFieldType.checkbox },
        { name: "remember", label: "Save my info in this browser for the next time I comment", type: FormFieldType.checkbox }
    ];

    const userInfo = getUserInfo();
    //   console.log("userINfo: ", userInfo)
    const initialValues = {} as BlogCommentPostRecord;
    if (userInfo) {
        initialValues.author_handle = userInfo.author_handle;
        initialValues.email = userInfo.email;
        initialValues.notify = userInfo.notify;
        initialValues.subscribe = userInfo.subscribe;
        initialValues.remember = true;
    }

    const handleSubmit = (values: Record<string, any> | null, id: string) => {
        console.log("values:", values)
        setMessage(undefined);
        if (!values) {
            props.onSubmit();
        } else if (values.content) {
            setReadyToPost(true);
            if (values.remember) {
                console.log("setting user info")
                setUserInfo({ author_handle: values.author_handle, email: values.email, notify: values.notify, subscribe: values.subscribe });
            }
        }
    }

    return (
        <>
            <CommentFormTitleText ref={titleRef}>Submit a Comment</CommentFormTitleText>
            {message && <StyledErrorText>{message}</StyledErrorText>}
            <SamForm forms={forms} id="comment" fields={fields} createSubmitButton={true} createCancelButton={true} handleSubmit={handleSubmit} />
            <ScrollRefDiv ref={scrollRef} />
            {showThankYou &&
                <SamModal closeBarColor={app.riverThemes.bannerBackColor} closeBarClicked={() => props.onSubmit()}>
                    <ThankYouBox>Thank you for contributing to the River. Your comment will be posted upon Karina's approval.</ThankYouBox>
                </SamModal>
            }
        </>
    )
}
// #endregion COMMENTS

export default Comments;