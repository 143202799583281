import React from 'react';
import styled from 'styled-components';

import MasterPage from '../components/MasterPage';
import { useGlobalContext } from '../libs/SamState';
import BlogContent from '../components/BlogContent';
import useData from '../components/useBlogData';

import app from '../appData';

const ErrorText = styled.p`
    font-size: 18px;
`
const Home: React.FC = () => {
    const [doScroll, setDoScroll] = React.useState(false);
    //   const [href, setHref] = React.useState(window.location.href);

    const { getContext } = useGlobalContext();
    const { updateUrl, getEntryList, formatListDescription } = useData();


    //console.log("HOME")
    React.useEffect(() => {
        updateUrl(() => setDoScroll(true));
    });
    // React.useEffect(() => {
    //     if (window.location.href !== href) {
    //         setHref(window.location.href);
    //         setCriteria(urlToCriteria());
    //     }
    // });
    React.useEffect(() => {
        if (doScroll) {
            setDoScroll(false);
            window.scrollTo(0, 0);
        }
    }, [doScroll]);

    if (getContext("fatalError")) {
        return (
            <ErrorText>
                Thanks for visiting Karinaland River. Our server is down right now. Please try again later.
            </ErrorText>
        )
    }
    const list = getEntryList();

    return (
        <MasterPage title={formatListDescription()}>
            {list && <BlogContent list={list} />}
        </MasterPage>
    );
}
//-------------------------------------------------------------------------------------

export default Home;