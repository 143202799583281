import React from 'react';
import styled, { keyframes } from 'styled-components'
import { useNavigate } from 'react-router-dom';

import app from '../appData';
import { formatImageUrl } from '../libs/ImageFormatter';
import { Divider } from '../libs/libSupport';
import SearchBox from './SearchBox';
import useData from './useBlogData';

import { BlogArchiveRecord } from '../interfaces/lib-websites-interfaces';

import '../reset.css';
import { useGlobalContext } from '../libs/SamState';

const menuTextFontSize = 16;
//--------------------------------------------------------
const SearchBurger = styled.div`
    z-index: 3000;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    top: 32px;
    left: 32px;
    color: white;
    input {
        cursor: default;
        border: none;
    }
`
const HamburgerContainer = styled.div`
    &:hover {
        color: ${app.themes.navbarHoverColor};
    }
    cursor: pointer;
    i {
        font-size: 40px;
    }
    p {
        font-family: ${app.themes.navbarFont};;
        margin-top: 0;
        font-size: ${menuTextFontSize}px;
        &:hover {
            font-style: italic;
        }
    }
    @media (max-width: ${app.positions.suppressSidebarWidth}px) {
        top: 4px;
        left: 4px;
        i {
            font-size: 20px;
        }
        p {
            font-size: 14px;
            margin-top: 0;
        }
    }
`
const slideOut = keyframes`
from {
    width: 0
}
to {
    width: ${app.positions.sidebarWidth}px;
}
`
/*
 0% { height: 100px; width: 100px; }
 30% { height: 400px; width: 400px; opacity: 1 }
 40% { height: 405px; width: 405px; opacity: 0.3; }
 100% { height: 100px; width: 100px; opacity: 0.6; }
*/

const SidebarContainer = styled.div`
    z-index: 3000;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    font-family: ${app.themes.sansFonts};
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh
    animation-name: ${slideOut};
    animation-duration: 500ms;
    background-color: ${app.riverThemes.sidebarBackColor};
    color: ${app.riverThemes.sidebarColor};
    p:hover {
        background-color: ${app.themes.buttonStyles.backgroundColor};
        font-style: italic;
    }
`
/*
const SidebarContainer = styled.div`
    z-index: 4000;
    position: fixed;
    font-family: ${app.themes.sansFonts};
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh
    background-color: ${app.riverThemes.sidebarBackColor};
    color: ${app.riverThemes.sidebarColor};
    p:hover {
        background-color: ${app.themes.buttonStyles.backgroundColor};
        font-style: italic;
    }
`
*/
const BannerBox = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 60px;
    background-image: url(${formatImageUrl(app.riverThemes.bannerImageFilename)});
    background-size: cover;
    margin-top: 16px;
    margin-bottom: 8px;
    cursor: pointer;
    div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
    }
`
const LogoImage = styled.img`
    width: 90%;
    height: auto;
    z-index: 2000;

`
const SidebarMenuItem = styled.p`
    width: 90%;
    margin: 4px auto 0 auto;
    font-size: 15px;
    line-height: 20px;
    font-weight: bold;
    color: ${app.riverThemes.sidebarColor};
    cursor: pointer;
`
const Sidebar: React.FC = () => {
    const navigate = useNavigate();
    const { getArchiveData } = useData();
    const { getContext, setContext } = useGlobalContext();

    const archiveData = getArchiveData();

    const sidebarRef = React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

    React.useEffect(() => {
        const sidebar = sidebarRef.current;
        if (sidebar) {
            if (getContext(app.positions.isSidebarDisplayedKey)) {
                window.scrollTo(0, 0);
                sidebar.classList.add("slideOut");
                sidebar.onanimationend = () => {
                    sidebar.classList.remove("slideOut");
                    sidebar.style.width = app.positions.sidebarWidth + "px";
                };
            } else {
                if (window.matchMedia("(max-width: " + app.positions.suppressSidebarWidth + "px)").matches) {
                    sidebar.style.width = "0";
                }
            }
        }
    }, [getContext(app.positions.isSidebarDisplayedKey)]);

    const menuClicked = () => {
        setContext(app.positions.isSidebarDisplayedKey, true);
    }
    const entryClicked = (url: string) => {
  //      if (window.matchMedia("(max-width: " + app.positions.suppressSidebarWidth + "px)").matches) {
            setContext(app.positions.isSidebarDisplayedKey, false);
  //      }
        if (url.startsWith("https")) {
            window.open(url, '_blank');
        } else {
            console.log("navigating to " + url)
            navigate(url);
        }
    }

    return (
        getContext(app.positions.isSidebarDisplayedKey) ? (
            <SidebarContainer ref={sidebarRef}>
                <BannerBox onClick={() => navigate("/")}>
                    <LogoImage src={formatImageUrl(app.riverThemes.logoImage)} />
                    <div />
                </BannerBox>
                <SearchBox style={{ width: "90%", backgroundColor: "white", color: "black", margin: "8px auto 12px auto", borderRadius: "6px", border: "1px solid " + app.riverThemes.bannerBackColor }} />
                <SidebarMenuItem onClick={() => entryClicked("/subscribe")}>Subscribe</SidebarMenuItem>
                <SidebarMenuItem onClick={() => entryClicked("/about")}>About</SidebarMenuItem>
                <SidebarMenuItem onClick={() => entryClicked("/mural")}>641 garage memorial mural</SidebarMenuItem>
                <SidebarMenuItem onClick={() => entryClicked("https://www.karinafilms.us/index.html")}>Karinafilms website</SidebarMenuItem>
                <SidebarMenuItem style={{ marginBottom: "-4px" }} onClick={() => entryClicked("https://goldthefilm.com/")}>Finding the Gold Within</SidebarMenuItem>
                {archiveData &&
                    <>
                        <Divider color="black" width="80%" height={1} margin={18} />
                        <ExpandableEntryList title="Topics" entries={archiveData.topics} entryClicked={entryClicked} />
                        <Divider color="black" width="80%" height={1} margin={12} />
                        <ExpandableEntryList marginTop={4} title="Archive" entries={archiveData.archive} entryClicked={entryClicked} />
                    </>
                }

            </SidebarContainer>
        ) : (
            <SearchBurger>
                <SearchBox isExpandable={true}
                    style={{ fontSize: menuTextFontSize + "px", width: "100%", backgroundColor: "white", 
                        color: app.riverThemes.titleBackColor, margin: "0 0 28px 0", borderRadius: "6px", border: "1px solid " + app.riverThemes.bannerBackColor }} />
                <HamburgerContainer>
                    <div onClick={menuClicked}>
                        <i className="fas fa-bars" />
                        <p>MENU</p>
                    </div>
                </HamburgerContainer>
            </SearchBurger>
        )
    )
}
//--------------------------------------------------------
const ExpandableEntryListContainer = styled.div<{ marginTop: number }>`
    width: 90%;
    margin: ${props => props.marginTop}px auto 12px auto;
    li {
        line-height: 16px;
        margin-top: 4px;
        margin-bottom: 4px;
        font-size: 13px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: ${app.riverThemes.sidebarColor};
        cursor: pointer;
    }
    li:hover {
        background-color: ${app.themes.buttonStyles.backgroundColor};
        font-style: italic;
    }
`
const IndexTitleContainer = styled.div`
    font-size: 18px;
    margin-bottom: 6px;
    width: 100%;
    color: ${app.riverThemes.sidebarColor};
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
`
const ShowMoreOrLessText = styled.p`
    text-align: center;
    margin: 0;
    cursor: pointer;
`
interface ExpandableEntryListProps {
    title: string;
    marginTop?: number;
    entries: BlogArchiveRecord[];
    entryClicked: (url: string) => void;
}
const minItemCount = 5;
const ExpandableEntryList: React.FC<ExpandableEntryListProps> = (props) => {
    const [isListOpen, setIsListOpen] = React.useState(true);

    return (
        <ExpandableEntryListContainer marginTop={props.marginTop ?? 0}>
            <IndexTitleContainer>
                <span>{props.title}</span>
            </IndexTitleContainer>
            {props.entries.map((entry, index) => {
                return (
                    (isListOpen || index < minItemCount) &&
                    <li onClick={() => props.entryClicked(entry.url)}>
                        {entry.caption}
                        <span>({entry.entry_count})</span>
                    </li>
                )
            })}
            <ShowMoreOrLessText onClick={() => setIsListOpen(state => !state)}>Show {isListOpen ? "less" : "more"}</ShowMoreOrLessText>
        </ExpandableEntryListContainer >
    )
}
export default Sidebar;

//                 <i className={isListOpen ? "fa fa-chevron-up" : "fa fa-chevron-down"} onClick={() => setIsListOpen(state => !state)} />
