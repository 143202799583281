const getArchiveData = "/api/getBlogArchiveData";
const getEntryList = "/api/getBlogEntryList";
const subscribe = "/api/blogsubscribe";
const postComment = "/api/blogPostComment";
const getGarageMemorial = "/api/getGarageMemorial";

export default {
    getArchiveData,
    getEntryList,
    getGarageMemorial,
    subscribe,
    postComment
}