import React, { JSXElementConstructor, PropsWithChildren, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

import MasterPage from '../components/MasterPage';
import { RenderFullEntry } from '../components/BlogContent';
import { BlogEntryList } from '../interfaces/lib-websites-interfaces';
import { genericApiError, useFetchApi } from '../libs/useDataApiV2';

import api from '../api-url';
import { ButtonsRow } from '../libs/IconButtonV2';

const FontsRow = styled.div`
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
`

const MuralMemorial: React.FC = () => {
    const [memorialEntries, setMemorialEntries] = React.useState<BlogEntryList>();
    const [font, setFont] = React.useState("Arimo");

    const { fetch } = useFetchApi();

    React.useEffect(() => {
        if (!memorialEntries) {
            fetch(api.getGarageMemorial, null, result => setMemorialEntries(result), () => alert(genericApiError));
        }
    }, []);

console.log("memorialEntries:", memorialEntries)
    const fonts = ["Arimo", "Quicksand", "Questrial", "Didact Gothic", "Outfit", "Lexend", "League Spartan"];
    return (
        <MasterPage>
            {memorialEntries &&
                <>
                    <FontsRow>
                        <select value={font} onChange={(e: any) => setFont(e.target.value)}>
                            {fonts.map(font => {
                                return (
                                    <option key={font} value={font}>{font}</option>
                                );
                            })}
                        </select>
                    </FontsRow>
                    <div style={{ fontFamily: font }}>
                        <p style={{fontWeight: "bold"}}>This is bold text</p>
                        <p style={{fontStyle: "italic"}}>This is italic text</p>
                        <RenderFullEntry entry={memorialEntries.entries[0]} suppressDate={true} />
                        <RenderFullEntry entry={memorialEntries.entries[1]} />
                        <RenderFullEntry entry={memorialEntries.entries[2]} />
                    </div>
                </>
            }
        </MasterPage>
    )
}
export default MuralMemorial;