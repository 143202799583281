import React from 'react';
import styled from 'styled-components';

const PagerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 16px auto;
    i:hover {
        font-size: 22px;
        font-weight: bold;
    }
`
const IconContainer = styled.i<{ backColor?: string; isDisabled: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.isDisabled ? "lightgray" : (props => props.backColor ?? "white")}
    color: ${props => props.isDisabled ? "ghostwhite" : undefined};
    width: 32px;
    height: 34px;
    font-size: 16px;
    cursor: ${props => props.isDisabled ? "default" : "pointer"};
`

interface PagerProps {
    currColor?: string;         // default to yellow
    chunkSize: number;
    recordCount: number;
    selectablePageCount?: number;
    loadChunk: (startIndex: number) => void;
}
const Pager: React.FC<PagerProps> = (props) => {
    const [currPage, setCurrPage] = React.useState(0);

    const currColor = props.currColor ?? "yellow";

    const pageCount = Math.floor(props.recordCount / props.chunkSize) + 1;
    const selectablePageCount = Math.min(props.selectablePageCount ?? 10, pageCount);
    let startPage = Math.max(0, currPage - Math.floor(selectablePageCount / 2));
    if (startPage + selectablePageCount > pageCount) {
        startPage = Math.max(0, pageCount - selectablePageCount);
    }

    React.useEffect(() => {
        props.loadChunk(currPage * props.chunkSize);
    }, [currPage]);

    const indexArray: number[] = [];
    for (let i = startPage; i < startPage + selectablePageCount && i < pageCount; i++) {
        indexArray.push(i + 1);
    }

    const firstPage = () => {
        setCurrPage(0);
    }
    const prevPage = () => {
        if (currPage > 0) {
            setCurrPage(currPage - 1);
        }
    }
    const getPage = (idx: number) => {
        setCurrPage(idx);
    }
    const nextPage = () => {
        if (currPage < pageCount - 1) {
            setCurrPage(currPage + 1);
        }
    }
    const lastPage = () => {
        setCurrPage(pageCount - 1);
    }

    return (
        <PagerContainer>
            <IconContainer isDisabled={currPage === 0} className="fas fa-backward" onClick={firstPage} />
            <IconContainer isDisabled={currPage === 0} className="fas fa-caret-left" onClick={prevPage} />
            {indexArray.map(idx => {
                return (
                    <IconContainer isDisabled={false} backColor={idx - 1 === currPage ? currColor : "white"} onClick={() => getPage(idx - 1)}>
                        {idx}
                    </IconContainer>
                )
            })}
            <IconContainer isDisabled={currPage === pageCount - 1} className="fas fa-caret-right" onClick={nextPage} />
            <IconContainer isDisabled={currPage === pageCount - 1} className="fas fa-forward" onClick={lastPage} />
        </PagerContainer>
    )
}
export default Pager;