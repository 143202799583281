import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

/* url format:
    id/{nnnnnnn}
    /archive/{season}/{year}?args
    /topic/{topicLabel}?args
    /search/{term}?args
    (any other retrieves most recent entries)
*/

// routes
import Home from './pages/Home';
import About from './pages/About';
import Admin from './pages/Admin';
import Subscribe from './pages/Subscribe';
import MuralMemorial from './pages/MuralMemorial';

export const AppRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/id/:entryId" element={<Home />} />
                <Route path="/archive/:season/:year" element={<Home />} />
                <Route path="/topic/:topicLabel" element={<Home />} />
                <Route path="/mural" element={<MuralMemorial />} />
                <Route path="/search/:term" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/subscribe" element={<Subscribe />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="*" element={<Home />} />
            </Routes>
        </BrowserRouter>
    );
}
export default AppRoutes;