import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc')

interface SeasonMapRecord {
    season: string;     // 'spring' or 'winter' etc.
    startMonth: number; // 1-relative
}
const seasons: SeasonMapRecord[] = [
    { season: "spring", startMonth: 3 },
    { season: "summer", startMonth: 6 },
    { season: "autumn", startMonth: 9 },
    { season: "winter", startMonth: 12 }
];

// NOTE FOR CONSTRUCTOR: month is 1-relative (in dayjs month is 0-relative)
export class DateSupport {
    dateTime: dayjs.Dayjs;

    constructor(year: number, month: number, date: number, hour: number = 0, minute: number = 0, second: number = 0, ms: number = 0) {
        this.dateTime = dayjs().year(year).month(month - 1).date(date).hour(hour).minute(minute).second(second).millisecond(ms);
    }

    static now = (): DateSupport => {
        const time = dayjs();
        return new DateSupport(time.year(), time.month() + 1, time.date(), time.hour(), time.minute(), time.second(), time.millisecond());
    }

    // static fromDate = (date: Date): DateSupport => {
    //     const time = dayjs(date);
    //     return new DateSupport(time.year(), time.month(), time.day(), time.hour(), time.minute(), time.second(), time.millisecond());
    // }

    // toDate = (): Date => {
    //     return dayjs().add(this.dateTime.utcOffset(), "minute").toDate();
    // }

    // returns current time disguised as UTC for passing as sql server parameter
    static sqlNow = (): Date => {
        return dayjs().add(dayjs().utcOffset(), "minute").toDate();
    }
    // convert any local date to fake UTC for passing as sql parameter
    static stringToSqlDate = (date: string): Date => {
        return dayjs(date).add(dayjs().utcOffset(), "minute").toDate();
    }
    static dateToSqlDate = (date: Date): Date => {
        return dayjs(date).add(dayjs().utcOffset(), "minute").toDate();
    }
    // create class from dayjs readable date; assume date is formatted as local time
    static fromString = (date: string): DateSupport => {
        const time = dayjs(date.replace(".000Z", ''));      // remove the utc indicator so formatting will show local
        return new DateSupport(time.year(), time.month() + 1, time.date(), time.hour(), time.minute(), time.second(), time.millisecond());
    }

    format = (formatter?: string): string => {
        if (formatter === "d") {
            return this.dateTime.format("MM/DD/YYYY");
        } else if (formatter === "g") {
            return this.dateTime.format("MM/DD/YYYY H:mm A");
        } else {
            return this.dateTime.format(formatter);
        }
    }

    // text should be formatted as season/year e.g.: "spring/2023"
    static seasonToDates = (text: string): { startDate: dayjs.Dayjs; endDatePlus: dayjs.Dayjs } => {
        try {
            const parts = text.split('/');
            if (parts.length !== 2) {
                throw '';
            }
            const startMonth = seasons.find(season => season.season === parts[0]);
            if (!startMonth) {
                throw '';
            }
            const year = parseInt(parts[1]);
            if (isNaN(year)) {
                throw '';
            }
            const startDate = dayjs(new Date(year, startMonth.startMonth - 1, 21));
            const endDatePlus = startDate.add(3, 'month');
            return { startDate, endDatePlus };
        } catch {
            throw "seasonToDates: invalid format on " + text;
        }
    }

    // classify months as if they start on the 21st, so 12/20 -> 11 and 12/21 -> 12 (i.e., Dec. starts on 12/21, etc.)pay_date
    // for use in grouping data by season
    static sqlSeasonCaseStatement = (field: string): string => {
        return "CASE WHEN DATEPART(mm," + field + ")=12 THEN CASE WHEN DATEPART(dd," + field + ")>=21 THEN 4 ELSE 3 END "
        + "WHEN DATEPART(mm," + field + ")=11 THEN 3 "
        + "WHEN DATEPART(mm," + field + ")=10 THEN 3 "
        + "WHEN DATEPART(mm," + field + ")=9 THEN CASE WHEN DATEPART(dd," + field + ")>=21 THEN 3 ELSE 2 END "
        + "WHEN DATEPART(mm," + field + ")=8 THEN 2 "
        + "WHEN DATEPART(mm," + field + ")=7 THEN 2 "
        + "WHEN DATEPART(mm," + field + ")=6 THEN CASE WHEN DATEPART(dd," + field + ")>=21 THEN 2 ELSE 1 END "
        + "WHEN DATEPART(mm," + field + ")=5 THEN 1 "
        + "WHEN DATEPART(mm," + field + ")=4 THEN 1 "
        + "WHEN DATEPART(mm," + field + ")=3 THEN CASE WHEN DATEPART(dd," + field + ")>=21 THEN 1 ELSE 4 END "
        + "WHEN DATEPART(mm," + field + ")=2 THEN 4 "
        + "WHEN DATEPART(mm," + field + ")=1 THEN 4 END";
    }
    // this must be used with sqlMonth21CaseStatement to make sure year is correct (Dec. 21-31 belongs to the next year)
    static sqlYearForSeasonCaseStatement = (field: string): string => {
        return "CASE WHEN DATEPART(mm," + field + ")=12 THEN DATEPART(yy," + field + ") "
        + "WHEN DATEPART(mm," + field + ")=11 THEN DATEPART(yy," + field + ") "
        + "WHEN DATEPART(mm," + field + ")=10 THEN DATEPART(yy," + field + ") "
        + "WHEN DATEPART(mm," + field + ")=9 THEN CASE WHEN DATEPART(dd," + field + ")>=21 THEN DATEPART(yy," + field + ") ELSE DATEPART(yy," + field + ") END "
        + "WHEN DATEPART(mm," + field + ")=8 THEN DATEPART(yy," + field + ") "
        + "WHEN DATEPART(mm," + field + ")=7 THEN DATEPART(yy," + field + ") "
        + "WHEN DATEPART(mm," + field + ")=6 THEN CASE WHEN DATEPART(dd," + field + ")>=21 THEN DATEPART(yy," + field + ") ELSE DATEPART(yy," + field + ") END "
        + "WHEN DATEPART(mm," + field + ")=5 THEN DATEPART(yy," + field + ") "
        + "WHEN DATEPART(mm," + field + ")=4 THEN DATEPART(yy," + field + ") "
        + "WHEN DATEPART(mm," + field + ")=3 THEN CASE WHEN DATEPART(dd," + field + ")>=21 THEN DATEPART(yy," + field + ") ELSE DATEPART(yy," + field + ")-1 END "
        + "WHEN DATEPART(mm," + field + ")=2 THEN DATEPART(yy," + field + ")-1 "
        + "WHEN DATEPART(mm," + field + ")=1 THEN DATEPART(yy," + field + ")-1 END";
    }
    // 1->spring, 2->summer etc.
    static indexToSeason = (index: number): string => {
        return seasons[index - 1].season;
    }
}