import React from 'react';
import styled from 'styled-components';

// alternative to dangerouslySetInnerHTML: clean HTML, split into paragraphs on <br /> and render

const replaceMap: { key: string, value: string }[] = [
    { key: "&#39;", value: "'"},
    { key: "&amp;", value: "&"},
    { key: "&nbsp;", value: " "}
];

const RenderContainer = styled.div`
    width: 100%;
`
const ParagraphText = styled.p`
    margin: 0;
`
interface RenderCleanHtmlProps {
    text: string;
    paragraphStyle?: Record<string, string>;
}
const RenderCleanHtml: React.FC<RenderCleanHtmlProps> = (props) => {
    const [text, setText] = React.useState<string[]>([]);
    React.useEffect(() => {
        setText(cleanAndSplitParagraphs(props.text));
    }, []);

    return (
        <RenderContainer>
            {text.map((graf, index) => {
                return (
                    <ParagraphText key={index} style={props.paragraphStyle}>{graf}</ParagraphText>
                )
            })}
        </RenderContainer>
    )
}
export const cleanAndSplitParagraphs = (html: string): string[] => {
    for (const entry of replaceMap) {
        html = html.replaceAll(entry.key, entry.value);
    }
    while (html.includes("  ")) {
        html = html.replaceAll("  ", " ");
    }
    html = html.replaceAll("<br /><br />", "<br />");
    return(html.split("<br />"));      // "paragraphs"
}
export default RenderCleanHtml;